import React from 'react';
import pommesService from "../Service/PommesService";

class PommesComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            pommes: []
        }
    }


    componentDidMount() {
        console.log('works')


        pommesService.getPommes().then(
            (response) => {
                console.log(response.data)
                this.setState({pommes: response.data})
            }
        )
    }


    render() {

        return (
            <div>
                <h1 className="text-center">Pommes</h1>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <td>Pommes ID</td>
                        <td>Name</td>
                        <td>Description</td>
                        <td>Price</td>
                    </tr>
                    </thead>
                    <tbody>
                    {

                        this.state.pommes.map(
                            pommes =>
                                <tr key={pommes.pommesId}>
                                    <td>{pommes.pommesId}</td>
                                    <td>{pommes.name}</td>
                                    <td>{pommes.description}</td>
                                    <td>{pommes.price}</td>
                                </tr>)
                    }
                    </tbody>
                </table>
            </div>
        )
    }


}

export default PommesComponent;