import './App.css';
import PommesComponent from "./Component/PommesComponent";

function App() {
  return (
    <div className="App">

        <h1>test</h1>
        <PommesComponent/>


    </div>
  );
}

export default App;
