import axios from 'axios';

const POMMES_REST_API_URL = 'https://www.hamburg-pommes.de/' + 'pommes';
//const POMMES_REST_API_URL = 'http://localhost:8080/' + 'pommes';

class PommesService {

    getPommes(){
        return axios.get(POMMES_REST_API_URL, {
            headers: {"Access-Control-Allow-Origin" : "*"}
        });
    }
}

export default new PommesService();